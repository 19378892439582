import React, { useEffect, useMemo } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';

import 'stream-chat-react/dist/css/v2/index.css';
import ChatContainer from './ChatContainer';
import { clearStreamTokens, upsertStreamUsers } from '../../actions/authActions';

const ALL_TAB = 'all';
const INDIVIDUAL_TAB = 'individual';
const GROUPS_TAB = 'groups';

export function Chats({ doClearStreamTokens, doUpsertStreamUsers, currentUser }) {
  const [activeTab, setActiveTab] = React.useState('all');
  const sort = { last_message_at: -1 };
  const filters = {
    [ALL_TAB]: { members: { $in: [currentUser.user_id] } },
    [INDIVIDUAL_TAB]: { members: { $in: [currentUser.user_id] }, type: 'messaging' },
    [GROUPS_TAB]: { members: { $in: [currentUser.user_id] }, type: { $in: ['group', 'broadcast'] } },
  };

  const tabButtons = [
    {
      label: 'All',
      id: ALL_TAB,
      icon: 'fa fa-list',
      onClick: () => setActiveTab(ALL_TAB),
    },
    {
      label: 'Individual',
      id: INDIVIDUAL_TAB,
      icon: 'fa fa-user',
      onClick: () => setActiveTab(INDIVIDUAL_TAB),
    },
    {
      label: 'Groups',
      id: GROUPS_TAB,
      icon: 'fa fa-user-friends',
      onClick: () => setActiveTab(GROUPS_TAB),
    },
  ];

  useEffect(() => {
    doClearStreamTokens();
    const users = {};
    users[currentUser.user_id] = {
      id: currentUser.user_id,
      name: `${currentUser.first_name} ${currentUser.last_name}`,
    };
    doUpsertStreamUsers(users);
  }, []);

  return (
    <ContentWrapper>
      <Row>
        <Col xs={12}>
          <PageTitle title="Messaging" />
        </Col>
      </Row>

      <div className="main-content-container mb-4">
        <Row className="mt-4 mb-4">
          <Col xs={12}>
            <Nav tabs className="d-none d-md-flex">
              {tabButtons.map((t) => (
                <NavItem
                  key={t.id}
                  className={activeTab === t.id.toLowerCase() ? 'active' : ''}
                >
                  <NavLink onClick={t.onClick}>
                    {t.icon && <em className={t.icon} />}
                    {t.label}
                  </NavLink>
                  <div className="tab-indicator animated" />
                </NavItem>
                  ))}
            </Nav>
          </Col>
        </Row>

        <TabContent activeTab={activeTab} className="animated no-top-margin">
          {tabButtons.map((t) => (
            <TabPane tabId={t.id} key={t.id}>
              {activeTab === t.id && (
                <ChatContainer filters={filters[t.id]} sort={sort} />
              )}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </ContentWrapper>
  );
}

Chats.propTypes = {
  currentUser: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  currentUser: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  doClearStreamTokens: () => dispatch(clearStreamTokens()),
  doUpsertStreamUsers: (users) => dispatch(upsertStreamUsers(users)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chats);
