import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import ChallengeTable from '../../components/Tables/ChallengeTable';
import { getChallenge, getActions, getLeaderboard } from '../../actions/challengeActions';
import ChallengeDetailContainer from '../../containers/Challenges/ChallengeDetailContainer';
import browserHistory from '../../history';
import { submitUserSearchRequest } from '../../actions/userActions';

class ChallengeDetail extends React.Component {
  componentDidMount() {
    const {
      team,
    } = this.props;

    if (team !== undefined && team.team_id) {
      this.loadChallenge();
    }
  }

  loadChallenge = () => {
    const {
      doGetChallenge,
      doGetActions,
      doGetLeaderboard,
      team,
      search,
      match: {
        params: { challenge_id },
      },
    } = this.props;

    doGetChallenge(challenge_id);
    doGetLeaderboard(challenge_id);
    doGetActions();
    search({});
  }

  render() {
    const { challenge = {}, user, team, actions, leaderboard, clients } = this.props;

	console.log('clients =>', clients);
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Challenge Detail" />
            <div className="mb-4 mt-n2">
              <Link to="/challenges">
                <em className="fa-lg mr-2 fas fa-arrow-left" />
                Back to Challenges
              </Link>
            </div>
          </Col>
        </Row>
        {challenge && challenge.name && actions && leaderboard && clients ? (
          <div className="main-content-container">
            <ChallengeDetailContainer challenge={challenge} actions={actions} leaderboard={leaderboard} clients={clients} />
          </div>
        ) : team && team.team_id ? (
          <Button onClick={this.loadChallenge}>Load Challenge</Button>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    );
  }
}

ChallengeDetail.propTypes = {
  doGetChallenge: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  actions: PropTypes.instanceOf(Array),
  doGetActions: PropTypes.func,
  leaderboard: PropTypes.instanceOf(Array),
  doGetLeaderboard: PropTypes.func,
  clients: PropTypes.instanceOf(Array),
};
const mapStateToProps = (state) => ({
  challenge: state.challenges.challenge,
  user: state.auth.user,
  team: state.team,
  actions: state.challenges.actions,
  leaderboard: state.challenges.leaderboard,
  clients: state.clients.userSearchResults,
});
const mapDispatchToProps = (dispatch) => ({
  doGetChallenge: (challenge_id) => {
    dispatch(getChallenge(challenge_id));
  },
  doGetLeaderboard: (challenge_id) => {
    dispatch(getLeaderboard(challenge_id));
  },
  doGetActions: () => {
    dispatch(getActions());
  },
  search: (values) => {
    dispatch(submitUserSearchRequest(values));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeDetail);
