import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import ContentForm from '../../forms/ContentForm';
import CustomFeatureModal from '../../modals/CustomFeatureModal';
import DeleteCustomFeatureModal from '../../modals/DeleteCustomFeatureModal';
import { submissionError } from '../../actions/formActions';
import {
  getCustomFeatures,
  getHiddenFeatures,
  addCustomFeature,
  updateCustomFeature,
} from '../../actions/teamActions';
import { camelCaseToUnderscoreCase } from '../../utils/stringUtils';
import customFeaturesOptions from '../../data/customFeatures.json';

class MenuLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customFeatureModalOpen: false,
      deleteCustomFeatureModalOpen: false,
    };
  }

  componentDidMount() {
    const { doGetCustomFeatures, doGetHiddenFeatures } = this.props;
    doGetCustomFeatures();
    doGetHiddenFeatures();
  }

  toggleCustomFeatureModal = (title = 'Add a Link') => {
    const { customFeatureModalOpen } = this.state;
    this.setState({
      customFeatureModalOpen: !customFeatureModalOpen,
      customFeatureModalTitle: title,
    });
  };

  toggleDeleteCustomFeatureModal = () => {
    const { deleteCustomFeatureModalOpen } = this.state;
    this.setState({
      deleteCustomFeatureModalOpen: !deleteCustomFeatureModalOpen,
    });
  };

  render() {
    const {
      customFeatureModalOpen,
      customFeatureModalTitle,
      customFeature,
      deleteCustomFeatureModalOpen,
    } = this.state;
    const {
      team,
      showSubmissionError,
      customFeatures,
      hiddenFeatures,
      doUpdateCustomFeature,
      doAddCustomFeature,
    } = this.props;
    let customLinks;
    const initialValues = {};
    if (customFeatures && customFeatures.length) {
      customLinks = customFeatures.filter((f) => f.content.type === 'profileMenuTeamTile');
    }
    for (let i = 0; i < customFeaturesOptions.length; i += 1) {
      const feat = customFeaturesOptions[i];
      initialValues[feat.value] = true;
    }
    if (hiddenFeatures && hiddenFeatures.length) {
      for (let i = 0; i < hiddenFeatures.length; i += 1) {
        initialValues[hiddenFeatures[i]] = false;
      }
    }

    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Customize Links" />
          </Col>
        </Row>
        <div className="main-content-container">
          <Row className="mt-2">
            <Col xs={12}>
              <div className="d-flex justify-space-between">
                <h4>Show the following links and content sections within the Macrostax app:</h4>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              {customFeatures !== undefined && hiddenFeatures !== undefined ? (
                <ContentForm
                  showSubmissionError={showSubmissionError}
                  onSubmit={(values) => {
                    // doSendInvitations(values);
                  }}
                  customFeatures={customFeatures}
                  initialValues={initialValues}
                  hiddenFeatures={hiddenFeatures}
                  team={team}
                />
              ) : (
                <Loading />
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <h4>Custom Links in App Menu</h4>
              <Button
                color="primary"
                onClick={() => {
                  this.setState(
                    {
                      customFeature: undefined,
                    },
                    () => {
                      this.toggleCustomFeatureModal();
                    }
                  );
                }}
              >
                <div className="d-flex flex-row align-items-center">
                  <em className="font-size-one-point-two-five icon-link mr-2" />
                  <div>Add a Link</div>
                </div>
              </Button>
            </Col>
          </Row>
          {customLinks !== undefined ? (
            <>
              {customLinks.length === 0 ? (
                <Row className="mb-2">
                  <Col>You don't have any custom links entered.</Col>
                </Row>
              ) : (
                <>
                  <Row className="mb-2 mt-4">
                    <Col className="d-flex align-items-end text-bold" md={1} xs={1}>
                      Icon
                    </Col>
                    <Col xs={8} className="text-bold">
                      Title
                    </Col>
                    {/* <Col xs={1} className="text-bold text-center">
                      Status
                    </Col> */}
                    <Col xs={2} className="text-bold text-center">
                      Actions
                    </Col>
                  </Row>
                  {customLinks.map((l) => (
                    <Row key={l.id}>
                      <Col xs={1} md={1} className="d-flex flex-row leaderboard-row">
                        <img
                          src={`/img/icons/${camelCaseToUnderscoreCase(l.content.icon).toLowerCase()}.svg`}
                          className="custom-link-icon"
                        />
                      </Col>
                      <Col xs={8} className="leaderboard-row">
                        <a href={l.content.link} target="_blank" rel="noreferrer">
                          {l.content.title}
                        </a>
                      </Col>
                      {/* <Col xs={1} className="leaderboard-row text-center">
                        archived?
                      </Col> */}
                      <Col xs={2} className="leaderboard-row text-center">
                        <span
                          className="span-link"
                          onClick={() => {
                            this.setState(
                              {
                                customFeature: l,
                              },
                              () => {
                                this.toggleCustomFeatureModal('Edit Link');
                              }
                            );
                          }}
                        >
                          Edit
                        </span>
                        <>
                          <span className="hidden-on-mobile"> | </span>
                          <span className="only-on-mobile"> </span>
                          <span
                            className="span-link"
                            title="Delete"
                            onClick={() => {
                              this.setState(
                                {
                                  customFeature: l,
                                },
                                () => {
                                  this.toggleDeleteCustomFeatureModal();
                                }
                              );
                            }}
                          >
                            Delete
                          </span>
                        </>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </>
          ) : null}
        </div>
        <CustomFeatureModal
          isOpen={customFeatureModalOpen}
          toggle={this.toggleCustomFeatureModal}
          title={customFeatureModalTitle}
          customFeature={customFeature}
          onSubmit={(values) => {
            if (values.id !== undefined && values.id.length > 0) {
              doUpdateCustomFeature(values);
            } else {
              doAddCustomFeature(values);
            }
            this.toggleCustomFeatureModal();
          }}
        />
        <DeleteCustomFeatureModal
          isOpen={deleteCustomFeatureModalOpen}
          toggle={this.toggleDeleteCustomFeatureModal}
          customFeature={customFeature}
        />
      </ContentWrapper>
    );
  }
}

MenuLinks.propTypes = {
  showSubmissionError: PropTypes.func,
  team: PropTypes.instanceOf(Object),
  doGetCustomFeatures: PropTypes.func,
  doGetHiddenFeatures: PropTypes.func,
  customFeatures: PropTypes.instanceOf(Array),
  hiddenFeatures: PropTypes.instanceOf(Array),
  doUpdateCustomFeature: PropTypes.func,
  doAddCustomFeature: PropTypes.func,
};
const mapStateToProps = (state) => ({
  team: state.team,
  customFeatures: state.team.customFeatures,
  hiddenFeatures: state.team.hiddenFeatures,
  currentUser: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doGetHiddenFeatures: () => dispatch(getHiddenFeatures()),
  doGetCustomFeatures: () => dispatch(getCustomFeatures()),
  doUpdateCustomFeature: (params) => dispatch(updateCustomFeature(params)),
  doAddCustomFeature: (params) =>
    dispatch(
      addCustomFeature({
        ...params,
        type: 'profileMenuTeamTile',
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuLinks);
