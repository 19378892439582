import { CLOSE_SIDE_MENU, OPEN_SIDE_MENU, SET_INITIAL_VALUES_FORM } from '../actions/groupActions';
import { ADD_GROUP_SUCCESS, EDIT_GROUP_SUCCESS, GET_GROUPS_SUCCESS } from '../apiActions/groupApiActions';
import { ADMIN_SEARCH_SUCCESS, GET_ALL_CLIENTS_SUCCESS } from '../apiActions/userApiActions';
import { ACTIVE_STATUS, INVITED_STATUS, PRE_ACTIVE_STATUS } from '../constants';
import { ROLES } from '../enums/roles';

const initialState = {
  visible: false,
  groupList: [],
  admins: [],
  members: [],
  initialValues: {
    id: null,
    name: '',
    admins: [],
    members: [],
  },
};

const isActive = (user) => user.user_role_status === ACTIVE_STATUS || PRE_ACTIVE_STATUS;
const isPendingInvite = (user) => user.user_role_status === INVITED_STATUS;

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SIDE_MENU:
      return {
        ...state,
        visible: true,
      };
    case CLOSE_SIDE_MENU:
      return {
        ...state,
        visible: false,
      };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groupList: action.data,
      };
    case GET_ALL_CLIENTS_SUCCESS: {
        const members = [];
        action.data.forEach((user) => {
          const label = user?.first_name && user?.last_name ?
            `${user.first_name} ${user.last_name}` :
            user.email;
          const value = user.id;

          if (user.role_id === ROLES.TEAM_CONSUMER && (isActive(user) || isPendingInvite(user))) {
            members.push({
              label,
              value,
              isActive: isActive(user),
              isPending: isPendingInvite(user),
            });
          }
        });

        return {
          ...state,
          members,
        };
      }
      case ADMIN_SEARCH_SUCCESS: {
        const admins = [];
        action.data.forEach((user) => {
          const label = user?.first_name && user?.last_name ?
            `${user.first_name} ${user.last_name}` :
            user.email;
          const value = user.id;

          if ((user.role_id === ROLES.TEAM_ADMIN || user.role_id === ROLES.TEAM_OWNER) && isActive(user)) {
            admins.push({
              label,
              value,
              isActive: true,
              isPending: false,
            });
          }
        });

        return {
          ...state,
          admins,
        };
      }
    case ADD_GROUP_SUCCESS: {
      return {
        ...state,
        groupList: [...state.groupList, action.data],
      };
    }
    case EDIT_GROUP_SUCCESS: {
      return {
        ...state,
        groupList: state.groupList.map((group) => {
          if (group.id === action.data.id) {
            return action.data;
          }

          return group;
        }),
      };
    }
    case SET_INITIAL_VALUES_FORM: {
      return {
        ...state,
        initialValues: action.values,
      };
    }
    default:
      return state;
  }
};
